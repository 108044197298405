.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.whiteText{
  color: white;
}

.goldText{
  color: goldenrod
}