*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.App{
  background-color: #f4f6f6;
}
.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 40px 10px;
}
.timeline-container::after {
  background-color: white;
  content: '';
  position: absolute;
  left: calc(50% - 2px);
  width: 4px;
  height: 100%;
}
.timeline-item{
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  position: relative;
  margin: 10px 0;
  width: 50%;
}
.timeline-item:nth-child(odd) {
  align-self: flex-end;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 0;
}
.timeline-item:nth-child(odd) p{
  text-align: left;
  align-items: flex-start;
}
.timeline-item:nth-child(odd) h4{
  text-align: left;
  align-items: flex-start;
}
.timeline-item:nth-child(odd) .timeline-item-content::after {
  right: auto;
  left: -7.5px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}
.timeline-item:nth-child(odd) .timeline-item-content .tag {
  left: auto;
  right: 5px;
}
.timeline-item:nth-child(odd) .timeline-item-content .circle {
  right: auto;
  left: -40px;
}
.timeline-item:nth-child(odd) .timeline-item-content .date {
  right: auto;
  left: -150px;
}
.timeline-item-content {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  width: 400px;
  max-width: 100%;
}
.timeline-item-content::after {
  background-color: #fff;
  box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: -7.5px;
  top: calc(50% - 7.5px);
  transform: rotate(45deg);
  width: 15px;
  height: 15px;
}
.timeline-item-content p{
  text-align: right;
  align-items: flex-start;
}
.timeline-item-content h4{
  text-align: right;
  align-items: flex-start;
}
.timeline-item-content .tag {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  top: 5px;
  left: 5px;
  letter-spacing: 1px;
  padding: 5px;
  position: absolute;
  text-transform: uppercase;
  border-radius: 10%;
}
.timeline-item-content p {
  font-size: 14px;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
}
.timeline-item-content h4 {
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  color: white;
}
.timeline-item-content a::after {
  font-size: 12px;
}
.timeline-item-content .circle {
  background-color: white;
  border: 2px solid #777;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 10px);
  right: -40px;
  width: 20px;
  height: 20px;
  z-index: 1;
}
img, .image{
  max-width:100%;
  max-height:100%;
  display: block;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}
.btn {
  border: none;
  outline: none;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0,0,0,0.15);
  background-color: #fff;
  border-radius: 16px;
  position: relative; 
  overflow: hidden; 
  transition: all 0.3s ease-in-out;
}
.btn:hover {
  box-shadow: 0 0 10px 5px rgba(0,0,0,0.3);
}
.input-tag {
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 5px 0;
  float: left;
}

.date-tag{
  float: right;
  padding: 5px 10px 0;
}

.timeline-item:nth-child(odd) .date-tag{
  float: left;
}

.timeline-item:nth-child(odd) .input-tag{
  float: right;
}
.input-tag__tags {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}
.input-tag__tags li {
  align-items: center;
  background-color: white;
  display: flex;
  font-weight: 300;
  list-style: none;
  margin-bottom: 5px;
  margin-right: 3px;
  padding: 0px 5px;
  border-radius: 16px;
  border: 2px solid white;
  opacity: 0.7;
  color: black;
  font-size: 14px;
}

.date-tag__tags li {
  align-items: center;
  background-color: white;
  display: flex;
  font-weight: 300;
  list-style: none;
  margin-bottom: 5px;
  margin-right: 3px;
  padding: 0px 5px;
  border-radius: 16px;
  color: black;
  font-size: 14px;
}

.timeline-item-align{
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  position: relative;
  margin: 10px 0;
  width: 50%;
  align-self: flex-end;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 0;
}

.timeline-item-align .timeline-item-content .circle {
  right: auto;
  left: -40px;
}
.timeline-item-align .timeline-item-content .date {
  right: auto;
  left: -150px;
}
.timeline-item-align .timeline-item-content p{
  text-align: left;
  align-items: flex-start;
}
.timeline-item-align .timeline-item-content h4{
  text-align: left;
  align-items: flex-start;
}

a{
  color: white;
}

a:link{
  text-decoration: none;
}

a:hover{
  color: white;
  text-decoration: underline;
}

a:visited{
  text-decoration: none;
  color: white;
}

@media only screen and (max-width: 600px) {
  .timeline-item .timeline-item-content .circle {
    right: auto;
    left: -30px;
  } 
  .timeline-item{
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    position: relative;
    margin: 10px 0;
    width: 100%;
    align-self: flex-end;
    justify-content: flex-start;
    padding-left: 30px;
    padding-right: 0;
  }
  .timeline-item .timeline-item-content .circle {
    right: auto;
    left: -30px;
  } 
  .timeline-item-align .timeline-item-content .circle {
    right: auto;
    left: -30px;
  } 
  .timeline-item-align{
    width: 100%;
  }
  .timeline-container::after {
    left: 8px;
  }

  .timeline-item:nth-child(odd) .timeline-item-content .circle {
    right: auto;
    left: -30px;
  }

  .timeline-item:nth-child(odd) .date-tag{
    float: right;
  }
  
  .timeline-item:nth-child(odd) .input-tag{
    float: left;
  }
}
